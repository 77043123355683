<template>
    <div>
        <div class="mb-3">
            <h5><i class="el-icon-info text-primary mr-2"></i><i>{{element.extraServiceDescription}}</i></h5>
        </div>
        <div v-html="element.note.replace(/\n/g, '<br>\n')"></div>
    </div>
</template>
<script>
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
            value: {
                type: String,
                default() {
                    return [];
                },
            },
        },
        watch: {
            element() {
                this.$emit('input', "OK");
            }
        },
        data() {
            return {
                enabledInput : false,
            };
        },
        created() {
            this.$emit('input', "OK");
        },
        methods: {

        }
    };
</script>